<template>
  <b-card>
    <validation-observer
      ref="infoRules"
      v-slot="{ invalid }"
      tag="form"
    >
      <b-row>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="engineerStatusForm.name"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Name"
                readonly
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="required"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="engineerStatusForm.email"
                type="email"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Email"
                readonly
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <!-- Server Errors -->
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            rules="required"
            name="certificates"
          >
            <b-form-group
              label="Certificates"
              label-for="certificates"
            >
              <b-form-checkbox-group
                id="certificates_id"
                v-model="engineerForm.certificates"
                class="d-flex flex-column"
                :options="optionsStatusId"
                trim
                placeholder="Certificates"
                :state="getValidationState(validationContext)"
                multiple
              />
              <b-form-invalid-feedback>{{
                validationContext.errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <b-card>
            <validation-observer
              ref="infoRules"
              tag="form"
            >
              <b-row>

                <b-col md="6">
                  <validation-provider
                    #default="validationContext"
                    rules="required"
                    name="Status"
                  >
                    <b-form-group
                      label="Status "
                      label-for="Status"
                    >
                      <b-form-checkbox-group
                        id="status_id"
                        v-model="engineerStatusForm.status_id"
                        class="d-flex flex-column"
                        :options="optionsStatus"
                        trim
                        placeholder="Status"
                        :state="getValidationState(validationContext)"
                        multiple
                      />
                      <!-- <div class="bg-black">
                Selected: <strong>{{ engineerForm.status_id }}</strong>
              </div> -->

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <!-- <b-col
                  cols="12"
                  class="mt-50"
                >
                  <b-button
                    v-if="!loaderStatus && $route.params.id"
                    variant="primary"
                    class="mr-1"
                    :disabled="invalid || Object.values(errors).length > 0"
                    @click="addUserStatusSite()"
                  >
                    Save Status Changes
                  </b-button>
                  <b-button
                    v-if="!loaderStatus && !$route.params.id"
                    variant="primary"
                    class="mr-1"
                    :disabled="invalid || Object.values(errors).length > 0"
                    @click="addUserStatusSite()"
                  >
                    Add
                  </b-button>
                  <b-button
                    v-if="loaderStatus"
                    variant="primary"
                    disabled
                    class="mr-1"
                  >
                    <b-spinner
                      small
                      type="grow"
                    />
                    Loading...
                  </b-button>
                </b-col> -->
              </b-row>
            </validation-observer>
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          v-for="(group, index) in selectedCertificateGroups"
          :key="index"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            rules="required"
            :name="`subCertificates${index}`"
          >
            <b-form-group
              :label="`Sub Certificates for ${group.text}`"
              :label-for="`sub_certificates_${index}`"
            >
              <b-form-checkbox-group
                :id="`sub_certificates_${index}`"
                v-model="engineerForm.subCertificates[group.value]"
                class="d-flex flex-column"
                :options="group.certificates"
                trim
                :state="getValidationState(validationContext)"
                multiple
              />
              <b-form-invalid-feedback>{{
                validationContext.errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          v-if="Object.values(errors).length > 0"
          cols="12"
        >
          <p
            v-for="(error, key, index) in errors"
            :key="index"
            class="text-danger small"
          >
            {{ error.toString() }}
          </p>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!loader && !loaderStatus"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addUserStatusAndSite"
          >
            Save All Changes
          </b-button>
          <b-button
            v-if="loader || loaderStatus"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from 'axios'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import decryptData from '@/store/auth/decryptData'

export default {
  data() {
    return {
      required,
      loader: false,
      validationErrors: {},
      errors: {},
      optionsStatusId: [],
      allCertificates: [],
      loaderStatus: false,
      id: this.$store.state.generalIds.id,
      optionsStatus: [],
    }
  },

  setup() {
    const { getValidationState } = formValidation()
    const engineerForm = ref({
      name: '',
      email: '',
      certificates: [],
      subCertificates: {},
    })
    const engineerStatusForm = ref({
      name: '',
      email: '',
      status_id: [],
    })

    return {
      getValidationState,
      engineerStatusForm,
      engineerForm,
    }
  },

  computed: {
    selectedCertificateGroups() {
      return this.engineerForm.certificates
        .map(cert => {
          const group = this.allCertificates.find(g => g.value === cert)
          if (group) {
            return {
              ...group,
              certificates: group.certificates.map(certif => ({
                value: certif.id,
                text: `${certif.name.ar} - ${certif.name.en}`,
              })),
            }
          }
          return null
        })
        .filter(group => group !== null)
    },
  },

  mounted() {
    this.getEngineerStatus()
    this.showCertificates()
    this.getEngineer()
    this.showEngineer()
  },

  methods: {
    getEngineer() {
      this.loadtable = true
      axios
        .get('engineer-status')
        .then(res => {
          this.optionsStatus = decryptData(res.data.payload).data.status.map(item => ({
            value: item.id,
            text: item.status,
          }))
        })
        .finally(() => {
          this.loadtable = false
        })
    },
    showEngineer() {
      if (this.$route.params.id) {
        axios.get(`/register/site/user/${this.$route.params.id}`).then(res => {
          const userData = decryptData(res.data.payload).data.user
          this.engineerStatusForm.name = userData.name
          this.engineerStatusForm.email = userData.email
          this.engineerStatusForm.status_id = userData.status.map(item => item.id)
        })
      } else {
        return false
      }
      return true
    },
    showCertificates() {
      if (this.$route.params.id) {
        axios.get(`/engineer/${this.$route.params.id}`).then(res => {
          const userData = decryptData(res.data.payload).data.engineer.certificates_groups
          this.engineerForm.certificates = userData.map(item => item.name.ar)
          userData.forEach(item => {
            const subCerts = item.certificates.map(cert => cert.id)
            this.$set(this.engineerForm.subCertificates, item.name.ar, subCerts)
          })
        })
      } else {
        return false
      }
      return true
    },
    getEngineerStatus() {
      this.loader = true
      axios
        .get('certificate')
        .then(res => {
          this.allCertificates = decryptData(res.data.payload).data.groups.map(item => ({
            value: item.name.ar,
            text: `${item.name.ar} - ${item.name.en}`,
            certificates: item.certificates,
          }))
          this.optionsStatusId = this.allCertificates.map(item => ({
            value: item.value,
            text: item.text,
          }))
        })
        .finally(() => {
          this.loader = false
        })
    },
    addUserSite() {
      this.loader = true
      const formData = new FormData()

      Object.keys(this.engineerForm.subCertificates).forEach(key => {
        this.engineerForm.subCertificates[key].forEach(subCert => {
          formData.append('certificates[]', subCert)
        })
      })

      axios
        .post(`/engineer/${this.$route.params.id}/certificate`, formData)
        .then(res => {
          if (res.status === 200 || res.status === 201) {
            // this.$router.push({ name: 'engineers' })
            // this.$toasted.show('Updated Successfully', {
            //   position: 'top-center',
            //   duration: 3000,
            // })
          }
        })
        .catch(error => {
          if (error.response.status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `${error.response.data.message}`,
                icon: 'BellIcon',
                variant: 'error',
              },
            })
          }
        })
        .finally(() => {
          this.loader = false
        })
    },

    addUserStatusSite() {
      if (this.$route.params.id) {
        this.loaderStatus = true
        const formData = new FormData()
        formData.append('_method', 'PUT')

        Object.keys(this.engineerStatusForm).forEach(key => {
          if (Array.isArray(this.engineerStatusForm[key])) {
            this.engineerStatusForm[key].forEach(item => {
              formData.append(`${key}[]`, item)
            })
          } else {
            formData.append(key, this.engineerStatusForm[key])
          }
        })

        axios
          .post(`/engineer/${this.$route.params.id}`, formData)
          .then(res => {
            if (res.status === 200) {
              // this.$toasted.show('Updated Successfully', {
              //   position: 'top-center',
              //   duration: 3000,
              // })
            }
          })
          .catch(error => {
            if (error.response.status) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `${error.response.data.message}`,
                  icon: 'BellIcon',
                  variant: 'error',
                },
              })
            }
          })
          .finally(() => {
            this.loaderStatus = false
          })
      }
    },

    async addUserStatusAndSite() {
      try {
        this.loader = true
        this.loaderStatus = true

        // Combine both methods into one
        await Promise.all([this.addUserSite(), this.addUserStatusSite()])

        this.$router.push({ name: 'engineers' })
        this.$toasted.show('Updated Successfully', {
          position: 'top-center',
          duration: 3000,
        })
      } catch (error) {
        if (error.response && error.response.status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `${error.response.data.message}`,
              icon: 'BellIcon',
              variant: 'error',
            },
          })
        }
      } finally {
        this.loader = false
        this.loaderStatus = false
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
